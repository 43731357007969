<script src="../../babel.config.js"></script>
<template>
    <div>
        <el-row style="margin-left: 80px;margin-top: 20px;">
            <el-col :span="11">
                <h1>用户数据中心</h1>
                <div>
                    <el-row class="item-row">
                        <el-col :span="10">
                            <h2>累计异常次数</h2>
                        </el-col>
                        <el-col :span="24" style="color: red">
                            <h2 style="color: red" v-if="tableData">{{ tableData.length }}次</h2>
                            <h2 style="color: red" v-else>0</h2>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-table
                                :data="tableData"
                                stripe
                                style="width: 100%"
                                max-height="400"
                        >
                            <el-table-column
                                    prop="date"
                                    label="时间">
                            </el-table-column>
                            <el-table-column
                                    prop="detail"
                                    label="异常情况">
                            </el-table-column>
                        </el-table>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-col :span="3"><img class="icon" src="../assets/icons/heart-content.png"></el-col>
                        <el-col :span="24">
                            <div>心率波动图像</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <OneLineChart v-if="heart_rate_x" :x-name="heart_rate_x" :line-data="heart_rate_y"
                                          id="heart-line"></OneLineChart>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-col :span="3"><img class="icon" src="../assets/icons/heart.png"></el-col>
                        <el-col :span="24">
                            <div>血压波动图像</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <OneLineChart v-if="blood_pressure_x" :x-name="blood_pressure_x"
                                          :line-data="blood_pressure_y" yLabel="cmHg" id="blood-pressure"></OneLineChart>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-col :span="3"><img class="icon" src="../assets/icons/heart-content.png"></el-col>
                        <el-col :span="24">
                            <div>血氧波动图像</div>
                        </el-col>
                    </el-row>
                    <el-row class="item-row">
                        <el-col :span="24" style="margin-top: -70px;">
                            <OneLineChart v-if="blood_oxygen_saturation_x" :x-name="blood_oxygen_saturation_x"
                                          :line-data="blood_oxygen_saturation_y" id="blood-oxygen"></OneLineChart>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
            <el-col :span="2" style="justify-content: center;align-items: center;display: flex;height: 100vh;">
                <el-divider direction="vertical"></el-divider>
            </el-col>
            <el-col :span="11">
                <div>
                    <h1 style="visibility:hidden;color: white ">占位</h1>
                    <el-row class="item-row">
                        <el-col :span="10">
                            <h2>累计上门核实</h2>
                        </el-col>
                        <el-col :span="24" style="color:red;">
                            <h2 style="color: red" v-if="tableData">{{ tableData.length }}次</h2>
                            <h2 style="color: red" v-else>0</h2>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row class="item-row">
                        <el-table
                                :data="tableData"
                                stripe
                                style="width: 100%"
                                max-height="400"
                        >
                            <el-table-column
                                    prop="date"
                                    label="时间">
                            </el-table-column>
                            <el-table-column
                                    prop="check"
                                    label="核实结果">
                            </el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="item-row">
                        <el-table
                                :data="heartLineTable"
                                stripe
                                height="300"
                                style="width: 100%;">
                            <el-table-column
                                    prop="date"
                                    label="时间">
                            </el-table-column>
                            <el-table-column
                                    prop="data"
                                    label="心率">
                            </el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="item-row">
                        <el-table
                                :data="bloodPressure"
                                stripe
                                height="300"
                                style="width: 100%"
                        >
                            <el-table-column
                                    prop="date"
                                    label="时间">
                            </el-table-column>
                            <el-table-column
                                    prop="data"
                                    label="血压">
                            </el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="item-row">
                        <el-table
                                :data="bloodOxygen"
                                stripe
                                height="300"
                                style="width: 100%">
                            <el-table-column
                                    prop="date"
                                    label="时间">
                            </el-table-column>
                            <el-table-column
                                    prop="data"
                                    label="血氧">
                            </el-table-column>
                        </el-table>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <div style="margin-bottom: 30px;margin-left: 80px;">
            <el-row class="item-row">
                <el-col :span="2"><img class="icon" src="../assets/icons/position.png"></el-col>
                <el-col :span="24">
                    <div>活动路径</div>
                </el-col>
            </el-row>
            <el-row class="item-row">
                <el-col :span="2"></el-col>
                <el-col :span="20">
                    <MapWithPath v-if="live_location_p" id="path-of-particle"
                                 :path-coordinates="live_location_p"></MapWithPath>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import OneLineChart from "@/components/OneLineChart.vue";
import Location from "@/components/Location.vue";
import LocationMap from "@/components/AMap.vue";
import MapWithPath from "@/components/MapWithPath.vue";
import VueMixin from "@/utils/VueMixin";
import {ExceptionType, convertCoordinates,getDateFromNumber} from "@/utils/common";

export default {
    name: "DataCenter",
    components: {OneLineChart, Location, LocationMap, MapWithPath},
    mixins: [VueMixin],
    data() {
        return {
            heart_rate_x: undefined,
            heart_rate_y: undefined,
            blood_pressure_y: undefined,
            blood_pressure_x: undefined,
            blood_oxygen_saturation_x: undefined,
            blood_oxygen_saturation_y: undefined,
            live_location_p: undefined,
            live_location_time: undefined,
            tableData: undefined,
            heartLineTable: undefined,
            bloodPressure: undefined,
            bloodOxygen: undefined,
            userId: undefined,

        }
    },
    computed: {
        errorInfo() {
            return {
                "7点10分18秒": "心率异常",
                "15点3分8秒": "血压异常",
            }
        },
    },
    mounted() {
        try {
            this.userId = this.$route.query.userId;
        } catch (e) {
            this.userId = undefined;
        }
        this.initData();
    },
    methods: {
        dealDate(type, data) {
            let json = data
            let arr = Object.keys(json).map(key => json[key]);
            let sortedArray = arr.flat().sort((a, b) => a.time - b.time);
            switch (type) {
                case "heart_rate":
                    this.heart_rate_y = sortedArray.reduce((acc, cur) => {
                        acc.push(cur.statics);
                        return acc;
                    }, []);
                    this.heart_rate_x = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    this.heartLineTable = sortedArray.reduce((acc, cur) => {
                        let obj = {
                            "date": this.getDateFromNumber2(cur.time),
                            "data": cur.statics
                        }
                        acc.push(obj);
                        return acc;
                    }, []);
                    break;
                case "blood_pressure":
                    this.blood_pressure_y = sortedArray.reduce((acc, cur) => {
                        acc.push(cur.statics);
                        return acc;
                    }, []);
                    this.blood_pressure_x = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    this.bloodPressure = sortedArray.reduce((acc, cur) => {
                        let obj = {
                            "date": this.getDateFromNumber2(cur.time),
                            "data": parseInt(cur.statics) + "/120"
                        }
                        acc.push(obj);
                        return acc;
                    }, []);
                    break;
                case "blood_oxygen_saturation":
                    this.blood_oxygen_saturation_y = sortedArray.reduce((acc, cur) => {
                        acc.push(cur.statics);
                        return acc;
                    }, []);
                    this.blood_oxygen_saturation_x = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    this.bloodOxygen = sortedArray.reduce((acc, cur) => {
                        let obj = {
                            "date": this.getDateFromNumber2(cur.time),
                            "data": parseInt(cur.statics)
                        }
                        acc.push(obj);
                        return acc;
                    }, []);
                    break;

                case "live_location":
                    this.live_location_p = sortedArray.reduce((acc, cur) => {
                        let coordinates = cur.statics.replace("N", "");
                        coordinates = coordinates.replace("W", "");
                        let obj = JSON.parse(coordinates)
                        acc.push(convertCoordinates(obj["lon"], obj["lat"]));
                        return acc;
                    }, []);
                    this.live_location_time = sortedArray.reduce((acc, cur) => {
                        acc.push(getDateFromNumber(cur.time));
                        return acc;
                    }, []);
                    break;
                default:
                    break;
            }
        },
        initData() {
            let type = ["heart_rate", "blood_pressure", "blood_oxygen_saturation", "live_location"];
            let url = undefined;
            url = `${this.$api.getStaticsByUserId}?user_id=${this.userId}`
            if (this.userId) {
                this.$bus.$emit("hideTab", {});
                console.log("到此一游")
                this.$http.get(url).then(res => {
                    if (res.data.data==="当前用户没有任何的监测记录") {
                        this.popWindow("当前用户没有任何的监测记录", "提示");
                        return;
                    }
                    if (res.data.code === 0) {
                        console.log(res.data.data)
                        console.log(res.data.data.blood_oxygen_saturation.staticses);
                        this.dealDate("blood_oxygen_saturation", res.data.data.blood_oxygen_saturation.staticses);
                        this.dealDate("blood_pressure", res.data.data.blood_pressure.staticses);
                        this.dealDate("heart_rate", res.data.data.heart_rate.staticses);
                        this.dealDate("live_location", res.data.data.live_location.staticses);
                    }
                })
            } else {
                for (let oneType of type) {
                    url = `${this.$api.getUserStatics}?scope=24&staticsName=${oneType}`
                    console.log(url);
                    this.$http.get(url).then(res => {
                        if (res.data.code === 0) {
                            switch (oneType) {
                                case "heart_rate":
                                    this.dealDate("heart_rate", res.data.data.staticses)
                                    break;
                                case "blood_pressure":
                                    this.dealDate("blood_pressure", res.data.data.staticses)
                                    break;
                                case "blood_oxygen_saturation":
                                    this.dealDate("blood_oxygen_saturation", res.data.data.staticses)
                                    break;
                                case "live_location":
                                    this.dealDate("live_location", res.data.data.staticses)
                                    break;
                                default:
                                    break;
                            }
                        }
                    })
                }
            }
            url = `${this.$api.getExceptionLog}?scope=24&user_id=${this.userId}`
            console.log(url);
            this.$http.get(url).then(res => {
                console.log(res)
                let data = res.data.data;
                let json = data
                let arr = Object.keys(json).map(key => json[key]);
                let sortedArray = arr.flat().sort((a, b) => a.time - b.time);
                this.tableData = sortedArray.reduce((acc, cur) => {
                    let obj = {};
                    obj["date"] = this.getDateFromNumber2(cur.time);
                    obj["detail"] = ExceptionType[cur.type.trim()];
                    obj["check"] = "误报"
                    acc.push(obj);
                    return acc;
                }, []);

            })
        },
        getDateFromNumber2(milliseconds) {
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = this.padZero(date.getMonth() + 1);
            const day = this.padZero(date.getDate());
            const hour = this.padZero(date.getHours());
            const minute = this.padZero(date.getMinutes());
            const second = this.padZero(date.getSeconds());
            return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
        },
        padZero(num) {
            return num < 10 ? '0' + num : num;
        }
    }
}
</script>

<style scoped>
.el-row {
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }
}

.el-col {
    border-radius: 4px;
}

.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    background: #d3dce6;
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

/deep/ .el-divider--vertical {
    height: 50vh;
}

.icon {
    height: 50px;
    width: 50px;
}

.item-row {
    display: flex;
    align-items: center;
}

#location {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}
</style>